import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Tooltip,
  TablePagination,
} from "@mui/material";
import { Edit, DeleteForever, Visibility } from "@mui/icons-material";
import TableHeader from "./components/table-header";
import styles from "./style.module.css";
import NewContactModal from "./components/new-contact-modal";
import {
  useContactsData,
  useDeleteContactsMutation,
  useContactsMutation,
  usePatchContactsMutation,
} from "src/hooks/useContactsData";
import Loader from "src/components/common/loader";
import dayjs from "dayjs";
import DeleteContactModal from "./components/delete-modal";
import classNames from "classnames";
import { showNotification } from "src/components/notification";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import NameProfilePic from "src/components/common/name-profile-pic";
import { authorizedGet } from "src/api/base";

const ContactsPage = () => {
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState({});
  const [filterQueryString, setFilterQueryString] = useState("");
  const [searchField, setSearchField] = useState("");
  const [formData, setFormData] = useState({});
  const [filterData, setFilterData] = useState({});
  const [value, setValue] = React.useState(dayjs());
  const [openFilter, setOpenFilter] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10); //eslint-disable-line
  const [query, setQuery] = useState({ limit: rowsPerPage, page: 1 });
  const navigate = useNavigate();

  const { data, isLoading } = useContactsData(query);
  const handleExportClick = async () => {
    try {
      const response = await authorizedGet({
        uri: `/api/v1/report/export-contacts/`,
      });
      showNotification("success", response.message , 8000);
    } catch (error) {       
      showNotification("error", error.message, 8000);
    }
  };
  const contactsMutation = useContactsMutation();
  const deleteContactsMutation = useDeleteContactsMutation();
  const patchContactsMutation = usePatchContactsMutation();

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries("contactsData");
  }, [page, queryClient, filterQueryString]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const params = new URLSearchParams();

      Object.entries(query).forEach(([key, value]) => {
        if (value) params.append(key, value);
      });
      navigate(`?${params}`, { replace: true }, { shallow: true });
      queryClient.invalidateQueries({ queryKey: ["leadsData"] });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [query, page]); //eslint-disable-line

  const handleChangePage = (event, newPage) => {
    const offset = newPage * rowsPerPage;
    setPage(newPage);
    setQuery({ ...query, page: newPage + 1, offset });
  };

  const handleSearch = async () => {
    if (searchField !== "") {
      setFilterQueryString((prev) => (prev += `&search=${searchField}`));
    }
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
    setOpen(false);
  };

  const handleReset = () => {
    setFormData({});
  };

  const handleFilterReset = (e) => {
    e.preventDefault();
    setFilterData({});
    setFilterQueryString("");
  };

  const handleChange = (e, type) => {
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
    console.log(formData);
  };

  const handleFilterDataChange = (event) => {
    const { name, value } = event.target;
    setFilterData({
      ...filterData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (edit) {
      try {
        await patchContactsMutation.mutateAsync(formData, {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["contactsData"] });
            showNotification("success", "Lead Updated Successfully.!", 2000);
            handleReset();
            setEdit(false);
            setOpen(false);
          },
        });
      } catch (error) {
        showNotification("error", error, 8000);
      }
    } else {
      try {
        await contactsMutation.mutateAsync(formData, {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["contactsData"] });
            showNotification("success", "Contacts Added Successfully.!", 2000);
            handleReset();
            setOpen(false);
          },
        });
      } catch (error) {
        showNotification("error", error, 8000);
      }
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      await deleteContactsMutation.mutateAsync(deleteUserData?.id, {
        onSuccess: (data) => {
          queryClient.invalidateQueries({ queryKey: ["contactsData"] });
          showNotification("success", "Contacts Deleted Successfully.!", 2000);
          handleReset();
          setOpen(false);
        },
      });
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };

  const handleFilterSubmit = async () => {
    // Construct the filter query string
    let filterQuery = "";

    // Check if source is not "all"
    if (filterData.source !== "all") {
      filterQuery += `&source=${filterData.source}`;
    }

    // Check if status is not "all"
    if (filterData.status !== "all") {
      filterQuery += `&status=${filterData.status}`;
    }

    // Check if lead_owner is not "all"
    if (filterData.lead_owner !== "all") {
      filterQuery += `&lead_owner=${filterData.lead_owner}`;
    }

    // Check if is_active is not "all"
    if (filterData.is_active !== "all") {
      filterQuery += `&is_active=${filterData.is_active === "active"}`;
    }

    setFilterQueryString(filterQuery);
  };

  const totalPages = Math.ceil((data?.count || 0) / rowsPerPage);

  return isLoading ? (
    <Loader />
  ) : (
    <Box>
      <ToastContainer />

      <Box className={styles["table_box"]}>
        <TableHeader
        handleExportClick={handleExportClick}
          styles={styles}
          handleOpen={() => setOpen(true)}
          openFilter={openFilter}
          handleOpenFilter={handleOpenFilter}
          handleCloseFilter={() => setOpenFilter(false)}
          value={value}
          setValue={setValue}
          handleSearch={handleSearch}
          searchField={searchField}
          setSearchField={setSearchField}
          filterData={filterData}
          handleFilterDataChange={handleFilterDataChange}
          handleFilterReset={handleFilterReset}
          handleFilterSubmit={handleFilterSubmit}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead className={styles["tHead"]}>
              <TableRow>
                <TableCell className={styles["table_cell"]}>
                  <Box className={styles["checkBoxDiv"]}>
                    <Checkbox className={styles["checkBox"]} />
                  </Box>
                </TableCell>
                {[
                  "Name",
                  "Email",
                  "Number",
                  "Destination",
                  "Decision Maker",
                ].map((item, index) => (
                  <TableCell key={index} className={styles["table_cell"]}>
                    {item}
                  </TableCell>
                ))}
                <TableCell
                  className={classNames(
                    styles["table_cell"],
                    styles["table_action_header"]
                  )}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.results?.map((row) => (
                <TableRow className={styles["table-body-row"]} key={row?.id}>
                  <TableCell className={styles["table_cell"]}>
                    <Box className="form-check">
                      <Checkbox
                        className="form-check-input"
                        name="chk_child"
                        value="option1"
                      />
                    </Box>
                  </TableCell>

                  <TableCell className={styles["table_cell"]}>
                    <Box className={styles["avatar_box"]}>
                      <Box className={styles["avatar_inner_box"]}>
                        <NameProfilePic
                          letter={row?.name[0].toUpperCase()}
                          customStyle={{
                            fontSize: "11px !important",
                          }}
                        />
                      </Box>
                      <Box className="flex-grow-1 ms-2 name">{row?.name}</Box>
                    </Box>
                  </TableCell>
                  <TableCell className={styles["table_cell"]}>
                    {row?.email}
                  </TableCell>
                  <TableCell
                    className={classNames(styles?.source, styles["table_cell"])}
                  >
                    {row?.number}
                  </TableCell>
                  <TableCell className="location">{row?.designation}</TableCell>
                  <TableCell className="location">
                    {row?.is_decision_maker ? "Yes" : "No"}
                  </TableCell>
                  <TableCell className={styles["row_action"]}>
                    <ul
                      style={{
                        width: "fit-content",
                        listStyle: "none",
                        padding: 0,
                        display: "flex",
                      }}
                    >
                      <li
                        className="list-inline-item"
                        onClick={() =>
                          navigate(`/dashboard/contacts/${row?.id}`)
                        }
                      >
                        <Tooltip
                          title="View"
                          placement="top"
                          onClick={() =>
                            navigate(
                              `/dashboard/contacts/${row?.id}?path=contact-summary`
                            )
                          }
                        >
                          <Visibility
                            sx={{
                              color: "#878a99",
                              marginRight: "0.6rem",
                              fontSize: "17px",
                            }}
                          />
                        </Tooltip>
                      </li>
                      <li
                        className="list-inline-item"
                        onClick={() => {
                          setFormData({
                            id: row?.id,
                            comment: row?.comment,
                            designation: row?.designation,
                            email: row?.email,
                            name: row?.name,
                            number: row?.number,
                          });
                          setEdit(true);
                          setOpen(true);
                        }}
                      >
                        <Tooltip title="Edit" placement="top">
                          <Edit
                            sx={{
                              color: "#878a99",
                              marginRight: "0.6rem",
                              fontSize: "17px",
                            }}
                          />
                        </Tooltip>
                      </li>
                      <li
                        className="list-inline-item"
                        onClick={() => {
                          setDeleteModal(true);
                          setDeleteUserData({
                            name: row?.first_name + row?.last_name,
                            id: row?.id,
                          });
                        }}
                      >
                        <Tooltip title="Delete" placement="top">
                          <DeleteForever
                            sx={{
                              color: "#878a99",
                              marginRight: "0.6rem",
                              fontSize: "17px",
                            }}
                          />
                        </Tooltip>
                      </li>
                    </ul>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[rowsPerPage]}
          component="div"
          count={data?.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          totalPages={totalPages}
        />
      </Box>

      <DeleteContactModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        deleteUserData={deleteUserData}
        setDeleteUserData={setDeleteUserData}
        handleDelete={handleDelete}
        styles={styles}
      />

      <NewContactModal
        open={open}
        styles={styles}
        handleReset={handleReset}
        handleOpen={() => setOpen(true)}
        handleClose={() => setOpen(false)}
        handleSubmit={handleSubmit}
        formData={formData}
        handleChange={handleChange}
        edit={edit}
        setEdit={setEdit}
      />
    </Box>
  );
};

export default ContactsPage;
