import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import styles from "./style.module.css";
import EmailsDashboard from "./components/dashboard";
import Templates from "./components/templates";
import SentEmails from "./components/sent-emails";

function Email() {
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    // Get the current URL query parameters
    const queryParams = new URLSearchParams(window.location.search);

    // Check if 'activeTab' is present in query parameters
    const activeTabParam = queryParams.get("activeTab");

    // If 'activeTab' exists and is a valid number, update the state
    if (activeTabParam && !isNaN(activeTabParam)) {
      setActiveTab(Number(activeTabParam));

      // Clear query parameters from the URL
      queryParams.delete("activeTab");
      const newUrl = `${window.location.pathname}${
        queryParams.toString() ? "?" + queryParams.toString() : ""
      }`;

      // Replace the current URL with the one without the query parameter, without reloading the page
      window.history.replaceState({}, "", newUrl);
    }
  }, []);

  return (
    <Box>
      <Box className={styles["tab-container"]}>
        <Box
          onClick={() => setActiveTab(0)}
          className={`${styles["tabs"]} ${activeTab === 0 && styles["active"]}`}
        >
          Dashboard
        </Box>
        <Box
          onClick={() => setActiveTab(1)}
          className={`${styles["tabs"]} ${activeTab === 1 && styles["active"]}`}
        >
          Templates
        </Box>
        <Box
          onClick={() => setActiveTab(2)}
          className={`${styles["tabs"]} ${activeTab === 2 && styles["active"]}`}
        >
          Send Email
        </Box>
      </Box>
      {activeTab === 0 && <EmailsDashboard />}
      {activeTab === 1 && <Templates />}
      {activeTab === 2 && <SentEmails />}
    </Box>
  );
}

export default Email;
