  import React from "react";
  import { Box, IconButton, Button, Grid, Paper, InputBase, Typography } from "@mui/material";
  import SearchIcon from "@mui/icons-material/Search";
  import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
  import LeadFilters from "./lead-filters";
  import { SaveAlt } from "@mui/icons-material";
  import "react-toastify/dist/ReactToastify.css";
  import { ToastContainer } from "react-toastify";
  import { authorizedGet } from "src/api/base";
  import { showNotification } from "src/components/notification";

  const TableHeader = ({
    totalLeads,
    styles,
    handleOpen,
    openFilter,
    handleOpenFilter,
    handleCloseFilter,
    filterData,
    handleFilterDataChange,
    handleFilterReset,
    selectedCount,
    handleDeleteSelected,
    query,
    handleChange,
  }) => {
    const handleExportClick = async () => {
      try {
        const response = await authorizedGet({
          uri: `/api/v1/report/export-leads/`,
        });
        showNotification("success", response.message , 8000);
      } catch (error) {       
        showNotification("error", error.message, 8000);
      }
    };
    return (
      <Box sx={{ p: 2, backgroundColor: "#E6EAEE", marginBottom: "10px" }}>
        <ToastContainer />
        <Grid container spacing={2} alignItems="center">
          {/* First Column */}
          <Grid item xs={12} sm={5}>
            <Box className={styles["total-lead"]}>
            <Typography  fontWeight={600} id="tableTitle" component="div">Total Leads : {totalLeads}</Typography>
            </Box>
          </Grid>

          {/* Search Bar */}
          <Grid item xs={12} sm={3}>
            <Paper component="form" className={styles["search-input"]}>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                name="search"
                defaultValue={query.search}
                onChange={handleChange}
                placeholder="Search Leads"
                inputProps={{ "aria-label": "search leads" }}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>

          {/* Action Buttons */}
          {selectedCount > 0 ? (
            <Grid item xs={12} sm={2}>
              <IconButton
                onClick={handleDeleteSelected}
                sx={{ p: "10px", color: "red" }}
                aria-label="delete selected"
              >
                <DeleteForeverIcon />
              </IconButton>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm={1} sx={{}}>
                <Button
                  variant="contained"
                  className={styles["button"]}
                  onClick={handleExportClick}
                  open={openFilter}
                  endIcon={<SaveAlt className={styles["export_btn_icon"]} />}
                >
                  <Box className={styles["export_btn"]}>Export</Box>
                </Button>
              </Grid>
              <Grid item xs={12} sm={1} sx={{ ml: 2 }}>
                <LeadFilters
                  openFilter={openFilter}
                  onOpenFilter={handleOpenFilter}
                  onCloseFilter={handleCloseFilter}
                  filterData={filterData}
                  handleFilterReset={handleFilterReset}
                  handleFilterDataChange={handleFilterDataChange}
                  styles={styles}
                />
              </Grid>
              <Grid item xs={12} sm={1} sx={{ ml: 2 }}>
                <Button
                  variant="contained"
                  onClick={handleOpen}
                  className={styles["create-btn"]}
                  // endIcon={<AddIcon className={styles["export_btn"]}/>}
                >
                  <Box className={styles["export_btn"]}>Create leads</Box>
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    );
  };

  export default TableHeader;
