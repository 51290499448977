import React from "react";
import { Box, IconButton, Grid, Paper, InputBase, Typography, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import OpportunityFilters from "./accounts-filters";
import { SaveAlt } from "@mui/icons-material";

const TableHeader = ({
  styles,
  openFilter,
  handleOpenFilter,
  handleExportClick,
  handleCloseFilter,
  value,
  setValue,
  filterData,
  handleFilterDataChange,
  handleFilterReset,
  handleFilterSubmit,
  handleSearch,
  searchField,
  setSearchField,
  total_accounts,
}) => {
  return (
    <Box sx={{ mb: 2, p: 2, backgroundColor: "#E6EAEE" }}>
      <Grid container spacing={2} alignItems="center">
        {/* Total Accounts on the Left */}
        <Grid item xs={12} sm={6} md={4}>
          <Box className={styles["total-account"]}>
            <Typography fontWeight={600} id="tableTitle" component="div">
              Total Accounts : {total_accounts}
            </Typography>
          </Box>
        </Grid>

        {/* Empty Grid item for spacing */}
        <Grid item xs={12} sm={6} md={4}></Grid>

        {/* Search, Filter, and Export Buttons on the Right */}
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2, // Adds space between filter and export button
          }}
        >
          <Paper
            component="form"
            className={styles["search-input"]}
            sx={{
              display: "flex",
              alignItems: "center",
              width: "300px",
            }}
          >
            <InputBase
              sx={{ flex: 1, pl: 1 }}
              value={searchField}
              onChange={(e) => {
                setSearchField(e.target.value);
                handleSearch();
              }}
              placeholder="Search Leads"
              inputProps={{ "aria-label": "search leads" }}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>

          <OpportunityFilters
            openFilter={openFilter}
            onOpenFilter={handleOpenFilter}
            onCloseFilter={handleCloseFilter}
            value={value}
            setValue={setValue}
            filterData={filterData}
            handleFilterDataChange={handleFilterDataChange}
            handleFilterReset={handleFilterReset}
            handleFilterSubmit={handleFilterSubmit}
            styles={styles}
          />

          <Button
            variant="contained"
            className={styles["button"]}
            onClick={handleExportClick}
            endIcon={<SaveAlt className={styles["export_btn_icon"]} />}
          >
            <Box className={styles["export_btn"]}>Export</Box>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TableHeader;
