import React, { useRef } from "react";
import { Box, Checkbox, Dialog, ListItemText, MenuItem, Select } from "@mui/material";
import { Close, DriveFolderUpload } from "@mui/icons-material";
import { CommonInputField } from "src/components/common/common-input-field";
import { SubmitButton } from "src/components/common/common-button";
import { CommonDropdownField } from "src/components/common/common-dropdown-field";
import { EMAIL_SOURCE_OPTIONS } from "src/utils/constants";
import { useLeadsData } from "src/hooks/useLeadsData";

const SendEmailModal = ({
  styles,
  open,
  handleClose,
  handleSubmit,
  templatesList,
  formData,
  setFormData,
  handleChange,
}) => {
  const fileRef = useRef();
  
  const { data } = useLeadsData();
  return (
    <Dialog open={open} fullWidth>
      <Box className={styles["main_header"]}>
        <Box className={styles["add_lead"]}>SEND EMAIL</Box>
        <Box>
          <Close
            className={styles["close"]}
            onClick={() => {
              handleClose();
            }}
          />
        </Box>
      </Box>
      <form onSubmit={handleSubmit} style={{ padding: "20px" }}>
        <Box className={styles["text_field"]}>
          <CommonDropdownField
            handleChange={handleChange}
            defaultValue={"default"}
            name="templates"
            placeholder="Templates"
            variant="outlined"
            value={formData?.template}
            options={templatesList ?? []}
            customClass={"half-width"}
          />
          <CommonDropdownField
            handleChange={handleChange}
            defaultValue={"default"}
            name="source"
            placeholder="Source"
            variant="outlined"
            value={formData?.source}
            options={EMAIL_SOURCE_OPTIONS}
            customClass={"half-width"}
          />
        </Box>
        {formData?.source === "manual" && (
          <CommonInputField
            minLines={4}
            placeholder={
              "Enter Comma Separated Email [E.g. abc@gmail.com, abcd@mail.com]"
            }
            value={formData?.manual_emails || []}
            name={"manual_emails"}
            handleChange={handleChange}
            customClass={styles["text-area"]}
          />

        )}
        {formData?.source === "lead_emails" && (
          <Select
            multiple
            className="common-dropdown"
            value={formData?.lead_emails || []}
            onChange={(e) => setFormData({ ...formData, lead_emails: e.target.value })}
            renderValue={(selected) =>
              selected.length ? selected.join(', ') : "Existing Leads' Emails" 
            }
            displayEmpty
            fullWidth
            variant="outlined"
            style={{ marginTop: "8px" }}
          >
            {!formData?.lead_emails?.length && (
              <MenuItem disabled value="">
                <em>Select Lead Emails</em>
              </MenuItem>
            )}

            {data?.results?.map((lead) => (
              <MenuItem key={lead.email} value={lead.email}>
                <Checkbox checked={formData?.lead_emails?.includes(lead.email)} />
                <ListItemText primary={lead.email} />
              </MenuItem>
            ))}
          </Select>
        )}
        {formData?.source === "upload_csv" && (
          <>
            <Box
              className={styles["upload-file-container"]}
              onClick={() => fileRef.current?.click()}
            >
              <input
                type="file"
                ref={fileRef}
                hidden
                onChange={(e) =>
                  setFormData({ ...formData, file: e.target.files[0] })
                }
              />
              {formData?.file ? (
                <Box className={styles["selected-file-name"]}>
                  {formData?.file?.name}
                </Box>
              ) : (
                <DriveFolderUpload />
              )}
            </Box>
          </>
        )}

        <SubmitButton
          fullWidth={true}
          text={"Send Email"}
          onClick={handleSubmit}
        />
      </form>
    </Dialog>
  );
};

export default SendEmailModal;
