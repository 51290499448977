import { format, getTime, formatDistanceToNow, parseISO } from "date-fns";

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || "dd MMM yyyy";

  return date ? format(new Date(date), fm) : "";
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || "dd MMM yyyy p";

  return date ? format(new Date(date), fm) : "";
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : "";
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : "";
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  return format(date, "dd-MM-yyyy");
}

export function formatDateTimeLocal(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export function formatDateAndTime(originalDate) {
  const parsedDate = parseISO(originalDate);
  return format(parsedDate, "dd-MM-yyyy HH:mm:ss");
}

export function formatDateAndTimeWithoutSec(originalDate) {
  const parsedDate = parseISO(originalDate);
  return format(parsedDate, "dd-MM-yyyy HH:mm");
}

export function formatTime(dateString) {
  const date = new Date(dateString);
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? String(hours).padStart(2, "0") : '12'; 

  return `${hours}:${minutes} ${ampm}`;
}
