import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Tooltip,
  Button,
} from "@mui/material";
import { Edit, DeleteForever, Visibility, SaveAlt } from "@mui/icons-material";
import styles from "./style.module.css";
import Loader from "src/components/common/loader";
import classNames from "classnames";
import {
  showNotification,
  showOldNotification,
} from "src/components/notification";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  useDeleteTemplateMutation,
  useTemplateData,
} from "src/hooks/useTemplateData";
import { formatDate } from "src/utils/formatTime";
import TemplateModal from "./components/template-modal";
import DeleteTemplateModal from "./components/delete-modal";
import { useQueryClient } from "@tanstack/react-query";
import { authorizedGet } from "src/api/base";

const Templates = () => {
  const [templateName, setTemplateName] = useState("");
  const [createTemplateOpen, setCreateTemplateOpen] = useState(false);
  const [deleteTemplateData, setDeleteTemplateData] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows
  const [selectAll, setSelectAll] = useState(false); // Track "select all" state

  const queryClient = useQueryClient();
  const { data: templates, isLoading } = useTemplateData();
  const deleteMutation = useDeleteTemplateMutation();
  const navigate = useNavigate();

  const handleRedirectToCreateTemplate = (e) => {
    e.preventDefault();
    if (templateName.length === 0 || !templateName) {
      showOldNotification("error", "Please Enter Template Name!");
      return;
    }
    navigate(`/dashboard/create-template/${templateName}`);
  };
  const handleExportClick = async () => {
    try {
      const response = await authorizedGet({
        uri: `/api/v1/report/export-email-template/`,
      });
      showNotification("success", response.message , 8000);
    } catch (error) {       
      showNotification("error", error.message, 8000);
    }
  };
  const deleteSelectedTemplate = (id) => {
    deleteMutation.mutate(
      { id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["emailTemplate"] });
          showNotification("success", "Template deleted successfully!", 2000);
          setDeleteTemplateData(null);
        },
        onError: (err) => {
          console.error("Error deleting template:", err);
          showNotification("error", err, 8000);
        },
      }
    );
  };

  // Handle individual row selection
  const handleRowSelect = (id) => {
    setSelectedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };


  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]); // Deselect all
    } else {
      setSelectedRows(templates?.results?.map((row) => row?.id) || []); // Select all rows
    }
    setSelectAll(!selectAll);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Box className={styles["table_box"]}>
      <ToastContainer />
      <DeleteTemplateModal
        styles={styles}
        handleDelete={() => deleteSelectedTemplate(deleteTemplateData?.id)}
        data={deleteTemplateData}
        handleClose={() => {
          setDeleteTemplateData(null);
        }}
      />
      <TemplateModal
        styles={styles}
        open={createTemplateOpen}
        handleClose={() => setCreateTemplateOpen(false)}
        handleSubmit={handleRedirectToCreateTemplate}
        templateName={templateName}
        setTemplateName={setTemplateName}
      />
      <Box className={styles["table-header"]}>
        <Box className={styles["total-lead"]}>
          <Box>Total templates: {templates?.results?.length}</Box>
        </Box>
        <Box>
          <Button
            variant="contained"
            className={styles["button"]}
            onClick={handleExportClick}
            endIcon={<SaveAlt className={styles["export_btn_icon"]} />}
          >
            <Box className={styles["export_btn"]}>Export</Box>
          </Button>
          <Button
            variant="contained"
            onClick={() => setCreateTemplateOpen(true)}
            className={styles["create-btn"]}
          >
            <Box className={styles["export_btn"]}>Create Template</Box>
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className={styles["tHead"]}>
            <TableRow>
              <TableCell className={styles["table_cell"]}>
                <Box className={styles["checkBoxDiv"]}>
                  <Checkbox
                    className={styles["checkBox"]}
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </Box>
              </TableCell>
              {["Template Name", "Created On", "Last Updated"].map(
                (item, index) => (
                  <TableCell key={index} className={styles["table_cell"]}>
                    {item}
                  </TableCell>
                )
              )}
              <TableCell
                className={classNames(
                  styles["table_cell"],
                  styles["table_action_header"]
                )}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templates?.results?.map((row) => (
              <TableRow
                className={styles["table-body-row"]}
                key={row?.id}
                selected={selectedRows.includes(row?.id)}
              >
                <TableCell className={styles["table_cell"]}>
                  <Box className="form-check">
                    <Checkbox
                      className="form-check-input"
                      name="chk_child"
                      value={row?.id}
                      checked={selectedRows.includes(row?.id)}
                      onChange={() => handleRowSelect(row?.id)}
                    />
                  </Box>
                </TableCell>
                <TableCell className={styles["table_cell"]}>
                  {row?.name}
                </TableCell>
                <TableCell className={styles["table_cell"]}>
                  {formatDate(row?.created_at)}
                </TableCell>
                <TableCell
                  className={classNames(styles?.source, styles["table_cell"])}
                >
                  {formatDate(row?.updated_at)}
                </TableCell>
                <TableCell className={styles["row_action"]}>
                  <ul
                    style={{
                      width: "fit-content",
                      listStyle: "none",
                      padding: 0,
                      display: "flex",
                    }}
                  >
                    <li
                      className="list-inline-item"
                      onClick={() =>
                        navigate(`/dashboard/preview-template/${row?.id}`)
                      }
                    >
                      <Tooltip title="View" placement="top">
                        <Visibility
                          sx={{
                            color: "#878a99",
                            marginRight: "0.6rem",
                            fontSize: "17px",
                          }}
                        />
                      </Tooltip>
                    </li>
                    <li
                      className="list-inline-item"
                      onClick={() =>
                        navigate(`/dashboard/preview-template/${row?.id}`)
                      }
                    >
                      <Tooltip title="Edit" placement="top">
                        <Edit
                          sx={{
                            color: "#878a99",
                            marginRight: "0.6rem",
                            fontSize: "17px",
                          }}
                        />
                      </Tooltip>
                    </li>
                    <li
                      className="list-inline-item"
                      onClick={() => {
                        setDeleteTemplateData({ id: row?.id, name: row?.name });
                      }}
                    >
                      <Tooltip title="Delete" placement="top">
                        <DeleteForever
                          sx={{
                            color: "#878a99",
                            marginRight: "0.6rem",
                            fontSize: "17px",
                          }}
                        />
                      </Tooltip>
                    </li>
                  </ul>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Templates;
