import React from "react";
import { Box, Dialog } from "@mui/material";
import { Close } from "@mui/icons-material";
import { CommonInputField } from "src/components/common/common-input-field";
import { SubmitButton } from "src/components/common/common-button";
import { CommonDropdownField } from "src/components/common/common-dropdown-field";
import { STAGE_OPTIONS } from "src/utils/constants";

const DealsModal = ({
  styles,
  formData,
  open,
  edit,
  handleClose,
  handleReset,
  handleChange,
  handleSubmit,
  setEdit,
}) => {
  return (
    <Box className={styles["main"]}>
      <Dialog open={open} fullWidth>
        <Box className={styles["main_header"]}>
          <Box className={styles["add_lead"]}>
            {edit ? "EDIT OPPORTUNITY" : "ADD OPPORTUNITY"}
          </Box>
          <Box>
            <Close
              className={styles["close"]}
              onClick={() => {
                handleClose();
                handleReset();
                setEdit(false);
              }}
            />
          </Box>
        </Box>
        {/* AVATAR IMAGE */}
        {/* <Box className={styles["img-main"]}>
          <Box className={styles["img_inner"]}>
            <Box className={styles["avatar"]}>
              <img src="/assets/images/avatars/user2.svg" alt="user avatar" />
            </Box>

            <Typography variant="h6">Lead Image</Typography>
          </Box>
        </Box> */}
        <form onSubmit={handleSubmit} style={{ padding: "20px" }}>
          <Box className={styles["text_field"]}>
            <CommonInputField
              customClass={styles["fixed-width"]}
              placeholder="Opportunity Amount"
              name="opportunity_amount"
              type="number"
              value={formData?.opportunity_amount}
              maxRows={1}
              handleChange={handleChange}
            />
            <CommonInputField
              maxRows={1}
              placeholder="Probability"
              name="probability"
              type="text"
              value={formData.probability}
              handleChange={handleChange}
              customClass={styles["fixed-width"]}
            />
          </Box>
          <Box className={styles["text_field"]}>
            <CommonDropdownField
              handleChange={handleChange}
              defaultValue={"default"}
              name="lead_stage"
              placeholder="Stage"
              variant="outlined"
              value={formData?.lead_stage}
              options={STAGE_OPTIONS}
              customClass={"half-width"}
            />
            <CommonInputField
              maxRows={1}
              placeholder="Description"
              name="description"
              type="text"
              value={formData.description}
              handleChange={handleChange}
              customClass={styles["fixed-width"]}
            />
          </Box>

          <SubmitButton
            fullWidth={true}
            customClass={styles.fullWidthButton}
            text={edit ? "Update Account" : "Add Account"}
            onClick={handleSubmit}
          />
        </form>
      </Dialog>
    </Box>
  );
};

export default DealsModal;
