import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card } from "@mui/material";
import Loader from "src/components/common/loader";

const styles = {
  card: {
    height: "100%",
    justifyContent: "space-between",
    width: "100%",
    padding: "8px 16px",
  },
  chartContainer: { display: "flex", flexDirection: "column" },
  title: {
    fontSize: "13px",
    border: " 1px solid #959495 ",
    borderLeft: "none",
    borderRight: "none",
    borderTop: "none",
    fontWeight: "bold",
    color: "#959495",
  },
  chart: (isLoading) => ({
    padding: "0 24px",
    width: "100%",
    display: isLoading ? "flex" : "block",
    alignItems: isLoading ? "center" : "flex-start",
    justifyContent: isLoading ? "center" : "flex-start",
  }),
};

const MultiLineCharts = ({ xAxisCategories, seriesData, isLoading }) => {
  const options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [2, 2, 2],
      curve: "straight",
    },
    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return (
          val +
          " - <strong>" +
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          "</strong>"
        );
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: xAxisCategories,
    },
    tooltip: {
      //   y: [
      //     {
      //       title: {
      //         formatter: function (val) {
      //           return val + " (mins)";
      //         },
      //       },
      //     },
      //     {
      //       title: {
      //         formatter: function (val) {
      //           return val + " per session";
      //         },
      //       },
      //     },
      //     {
      //       title: {
      //         formatter: function (val) {
      //           return val;
      //         },
      //       },
      //     },
      //   ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return (
    <Card sx={styles?.card}>
      <div style={styles.chart(isLoading)}>
        {isLoading ? (
          <Loader />
        ) : (
          <ReactApexChart
            options={options}
            series={seriesData}
            type="line"
            height={420}
          />
        )}
      </div>
    </Card>
  );
};

export default MultiLineCharts;
