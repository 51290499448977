import React, { useState } from "react";
import styles from "./style.module.css";
import {
  Paper,
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  Add,
  DeleteForever,
  Edit,
  SaveAlt,
  Visibility,
} from "@mui/icons-material";
import classNames from "classnames";
import { formatTime, formatDate } from "src/utils/formatTime";
import SendEmailModal from "./components/send-email-modal";
import {
  useEmailHistoryData,
  useSendBulkEmailMutation,
  useTemplateData,
  useUploadEmailMutation,
} from "src/hooks/useTemplateData";
import Loader from "src/components/common/loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showNotification } from "src/components/notification";
import { useNavigate } from "react-router-dom";
import { authorizedGet } from "src/api/base";

const SentEmails = () => {
  const [sendEmailOpen, setSendEmailOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows

  const [formData, setFormData] = useState({});
  const SendBulkEmailMutation = useSendBulkEmailMutation();
  const SendBulkEmailCSVMutation = useUploadEmailMutation();
  const { data: templates, isLoading } = useTemplateData();
  const { data: history } = useEmailHistoryData();
  const navigate = useNavigate();

  const resetFormData = () => ({
    manual_emails: [],
    lead_emails: [],
    file: null,
  });

  const manualEmails = Array.isArray(formData.manual_emails)
    ? formData.manual_emails
    : formData.manual_emails
        ?.split(",")
        .map((email) => email.trim())
        .filter((email) => email !== "");

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "source") {
      setFormData({ ...resetFormData(), source: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Checkbox Handlers
  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id) 
        : [...prevSelected, id] 
    );
  };

  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      const allIds = history?.map((row) => row.id) || [];
      setSelectedRows(allIds);
    } else {
      setSelectedRows([]); 
    }
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();
    try {
      if (formData?.source === "upload_csv") {
        const formDataObj = new FormData();
        formDataObj.append("file", formData?.file);
        formDataObj.append("template_id", formData?.templates);
        await SendBulkEmailCSVMutation.mutateAsync(formDataObj, {
          onSuccess: () => {
            showNotification(
              "success",
              "CSV processed and emails sent successfully!",
              8000
            );
          },
          onError: (error) => {
            console.log(error);
            showNotification("error", error, 8000);
          },
        });
      } else {
        await SendBulkEmailMutation.mutateAsync(
          {
            emails:
              formData?.source === "lead_emails"
                ? formData?.lead_emails.join(", ")
                : manualEmails.join(", "),
            template_id: formData?.templates,
          },
          {
            onSuccess: () => {
              showNotification("success", "All Emails Sent Successfully!", 8000);
            },
            onError: (error) => {
              console.log(error);
              showNotification("error", error, 8000);
            },
          }
        );
      }
    } catch (error) {
      console.error("Error sending emails:", error);
      showNotification("error", error, 8000);
    }
  };
  const handleExportClick = async () => {
    try {
      const response = await authorizedGet({
        uri: `/api/v1/report/export-email-history/`,
      });
      showNotification("success", response.message , 8000);
    } catch (error) {       
      showNotification("error", error.message, 8000);
    }
  };
  return isLoading ? (
    <Loader />
  ) : (
    <Box className={styles["table_box"]}>
      <ToastContainer />
      <SendEmailModal
        templatesList={templates?.results?.map((item) => {
          return { label: item?.name, value: item?.id };
        })}
        open={sendEmailOpen}
        styles={styles}
        handleChange={handleChange}
        handleClose={() => setSendEmailOpen(false)}
        formData={formData}
        setFormData={setFormData}
        handleSubmit={handleSendEmail}
      />
      {/* Table Header */}
      <Box className={styles["table-header"]}>
        <Box className={styles["total-lead"]}>
          <Box>Total Sent Emails: {history?.length || 0}</Box>
        </Box>
        <Box>
          <Button
            variant="contained"
            className={styles["button"]}
            onClick={handleExportClick}
            endIcon={<SaveAlt className={styles["export_btn_icon"]} />}
          >
            <Box className={styles["export_btn"]}>Export</Box>
          </Button>
          <Button
            variant="contained"
            onClick={() => setSendEmailOpen(true)}
            className={styles["create-btn"]}
            endIcon={<Add />}
          >
            <Box className={styles["export_btn"]}>Send New Email</Box>
          </Button>
        </Box>
      </Box>
      {/* Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead className={styles["tHead"]}>
            <TableRow>
              <TableCell className={styles["table_cell"]}>
                <Box className={styles["checkBoxDiv"]}>
                  <Checkbox
                    className={styles["checkBox"]}
                    onChange={handleSelectAllChange}
                    checked={
                      history?.length > 0 &&
                      selectedRows.length === history.length
                    }
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < history.length
                    }
                  />
                </Box>
              </TableCell>
              {["Template Name", "Email List", "Created On", "Sent On"].map(
                (item, index) => (
                  <TableCell key={index} className={styles["table_cell"]}>
                    {item}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {history?.map((row) => (
              <TableRow className={styles["table-body-row"]} key={row?.id}>
                <TableCell className={styles["table_cell"]}>
                  <Box>
                    <Checkbox
                      name="chk_child"
                      checked={selectedRows.includes(row?.id)}
                      onChange={() => handleCheckboxChange(row?.id)}
                    />
                  </Box>
                </TableCell>
                <TableCell
                  onClick={() =>
                    navigate(`/dashboard/preview-template/${row?.template}`)
                  }
                  className={styles["table_cell"]}
                >
                  {row?.template_name}
                </TableCell>
                <TableCell className={styles["table_cell"]}>
                  {row?.email_list
                    .split(",")
                    .map((email) => email.trim() + ", ")}
                </TableCell>
                <TableCell className={styles["table_cell"]}>
                  {formatDate(row?.created_at)}
                </TableCell>
                <TableCell className={styles["table_cell"]}>
                  {formatTime(row?.created_at)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SentEmails;
