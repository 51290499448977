import React, { useState } from "react";
import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  Typography,
  IconButton,
} from "@mui/material";
import Slider from "@mui/material/Slider";
import Iconify from "src/components/iconify";

export default function Filters({
  openFilter,
  onOpenFilter,
  onCloseFilter,
  onRangeChange,
  dateRanges,
  rangeValues,
}) {
  const [rangeIndex, setRangeIndex] = useState(0);

  const handleRangeChange = (event, newIndex) => {
    setRangeIndex(newIndex);
    onRangeChange(rangeValues[newIndex]);
  };

  return (
    <>
      <Button
        disableRipple
        color="inherit"
        endIcon={<Iconify icon="ic:round-filter-list" fontSize="small" />}
        onClick={onOpenFilter}
        sx={{
          padding: "2px 18px",
          height: "33px",
          backgroundColor: "#117FB5",
          fontWeight: "400",
          borderRadius: "3px",
          color: "#ffff",
          minWidth: "110px !important",
          marginRight: "10px",
          "&:hover": { backgroundColor: "#0B90D2", color: "#fff" },
        }}
      >
        Filters
      </Button>

      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: {
            width: 320,
            borderRadius: "10px 0 0 10px",
            overflow: "hidden",
            backgroundColor: "#F9FAFB",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.3s ease-in-out",
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 2, py: 2 }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              alt="icon"
              src="/assets/icons/dashboard/filter.svg"
              style={{ height: "30px", width: "30px" }}
            />
            <Typography variant="h6" sx={{ ml: 1, fontWeight: 500 }}>
              Filters
            </Typography>
          </Box>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Stack spacing={3} sx={{ p: 3 }}>
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
              Date Range
            </Typography>
            <Slider
              value={rangeIndex}
              min={0}
              max={dateRanges.length - 1}
              step={1}
              marks={dateRanges.map((label, index) => ({ value: index, label }))}
              onChange={handleRangeChange}
              valueLabelDisplay="auto"
              valueLabelFormat={(index) => dateRanges[index]}
              sx={{
                mt: 2,
                "& .MuiSlider-rail": {
                  backgroundColor: "#e0e0e0",
                },
                "& .MuiSlider-track": {
                  backgroundColor: "#117FB5",
                },
                "& .MuiSlider-thumb": {
                  backgroundColor: "#117FB5",
                  border: "2px solid #fff",
                },
              }}
            />
            <Typography variant="body2" sx={{ mt: 1 }}>
              Selected Range: {(rangeValues[rangeIndex]).replace("_", " ")}
            </Typography>
          </Box>
        </Stack>

        <Box sx={{ p: 3 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            startIcon={<Iconify icon="ic:round-clear-all" />}
            onClick={() => {
              setRangeIndex(0);
              onRangeChange("7_days");
            }}
            
          >
            Clear All
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
