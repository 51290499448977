import React from "react";
import { Box, Dialog } from "@mui/material";
import { Close } from "@mui/icons-material";
import { CommonInputField } from "src/components/common/common-input-field";
import { SubmitButton } from "src/components/common/common-button";

const TemplateModal = ({
  styles,
  templateName,
  setTemplateName,
  open,
  handleClose,
  handleSubmit,
}) => {
  return (
    <Dialog open={open} fullWidth>
      <Box className={styles["main_header"]}>
        <Box className={styles["add_lead"]}>CREATE TEMPLATE</Box>
        <Box>
          <Close
            className={styles["close"]}
            onClick={() => {
              handleClose();
              setTemplateName("");
            }}
          />
        </Box>
      </Box>
      <form onSubmit={handleSubmit} style={{ padding: "20px" }}>
        <CommonInputField
          placeholder="Template Name"
          name="name"
          type="text"
          value={templateName}
          handleChange={(e) => setTemplateName(e.target.value)}
        />

        <SubmitButton
          fullWidth={true}
          text={"Create Template"}
          onClick={handleSubmit}
        />
      </form>
    </Dialog>
  );
};

export default TemplateModal;
